<template>
  <div class="vi-rgister-parent-self">
    <svg width="100%" height="450" class="web-bg-svg">
      <rect
        width="150%"
        height="450"
        style="fill:rgb(41, 84, 162);"
        transform="rotate(-13 0,750) skewX(8)"
      ></rect>
    </svg>
    <div class="container">
      <div class="row mt-4 mb-5">
        <div class="col-md-6 my-auto">
          <img
            src="../../assets/img/registration.svg"
            width="510"
            class="img-fluid heroimg"
            alt="Vidhyaan"
            title="Vidhyaan"
          />
        </div>
        <div class="col-md-6">
          <div class="card web-login-card p-4 mt-2 mb-5" v-if="verifyCode == false">
            <div class="dispflex">
            <div style="margin-bottom:0;line-height:18px;">
              You are registering as a
              <h1>Parent/Self</h1>
            </div>
            <div class="cardIcn"  @click.prevent="backToHome">
              
              <svg width="20px" height="20px" viewBox="0 0 28 28" version="1.1">
                <defs>
                  <path d="M0 0L28 0L28 28L0 28L0 0Z" id="path_1" />
                  <clipPath id="mask_1">
                    <use xlink:href="#path_1" />
                  </clipPath>
                </defs>
                <g id="Home-icon">
                  <path d="M0 0L28 0L28 28L0 28L0 0Z" id="Background" fill="#FFFFFF" fill-opacity="0" fill-rule="evenodd" stroke="none" />
                  <g clip-path="url(#mask_1)">
                    <path d="M13.9998 0.588379C13.878 0.588379 13.7567 0.62701 13.6531 0.70541L0.773084 10.7581C0.532284 10.9485 0.487528 11.3003 0.677928 11.5467C0.868328 11.7875 1.22012 11.8322 1.46652 11.6418L2.2398 11.0381L2.2398 25.7599C2.2398 26.0679 2.4918 26.3199 2.7998 26.3199L10.6398 26.3199L10.6398 16.2399L17.3598 16.2399L17.3598 26.3199L25.1998 26.3199C25.5078 26.3199 25.7598 26.0679 25.7598 25.7599L25.7598 11.0381L26.5331 11.6418C26.6395 11.7202 26.7566 11.7599 26.8798 11.7599C27.0478 11.7599 27.2097 11.6867 27.3217 11.5467C27.5121 11.3003 27.4673 10.9485 27.2265 10.7581L14.3465 0.70541C14.2429 0.62701 14.1216 0.588379 13.9998 0.588379L13.9998 0.588379ZM19.5996 2.80005L19.5996 3.38849L22.9596 6.00911L22.9596 2.80005L19.5996 2.80005L19.5996 2.80005Z" id="Shape" fill="#000000" fill-rule="evenodd" stroke="none" />
                  </g>
                </g>
              </svg>
            </div>
            </div>
            <form @submit.prevent="mxSubmit" data-vv-scope="parentRegister" id="parentRegisterid" accept-charset="utf-8">
              <div class="form-row">
                <div class="form-group input-group col-md-12">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      name="fname"
                      v-validate="'required'"
                      class="form-control web-form-input"
                      id="fnameid"
                      placeholder="First Name"
                      v-model="firstName"
                      maxlength="50"
                    />
                  <label
                    v-if="errors.first('parentRegister.fname') && showError"
                    class="vi-error"
                    style="top: -1em"
                    >First Name is required</label
                  >
                    <label for="fnameid" v-else
                      >First Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-12">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      name="lname"
                      class="form-control web-form-input"
                      id="lnameid"
                      v-validate="'required'"
                      placeholder="Last Name"
                      v-model="lastName"
                      maxlength="50"
                    />
                   <label
                    v-if="errors.first('parentRegister.lname') && showError"
                    class="vi-error"
                    style="top: -1em"
                    >Last Name is required</label
                  >
                    <label for="lnameid" v-else
                      >Last Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-12">
                  <span class="web-has-float-label">
                    <input
                      @focusout="checkEmail"
                      type="email"
                      name="email"
                      v-validate="'required'"
                      class="form-control web-form-input"
                      id="emailid"
                      placeholder="Email Id"
                      v-model="emailID"
                      autocomplete="off"
                      style="cursor: text;"
                      maxlength="50"
                    />

                    <label
                    v-if="(errors.first('parentRegister.email') && showError) || !isEmailIdAvailable"
                    class="vi-error"
                    style="top: -1em"
                    >{{ errors.first('parentRegister.email') ? 'Email Id is required ' : 'Email already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }} </label
                  >

                    <label for="emailid" v-else
                      >Email Id <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-12">
                  <span class="web-has-float-label">
                    <input
                      type="number"
                      onKeyPress="if(this.value.length==10) return false;" 
                      @input="checkMobile"
                      v-validate="'required|numeric|min:10|max:10'"
                      name="mobno"
                      class="form-control web-form-input"
                      id="mobnoid"
                      placeholder="Mobile"
                      v-model="mobileNo"
                    />
                    <label
                    v-if="(errors.first('parentRegister.mobno') && showError) || !isPhoneNumberAvailable"
                    class="vi-error"
                    style="top: -1em"
                    >{{ errors.first('parentRegister.mobno') ? 'Please enter a valid mobile number(10 digits)' : 'Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                  >
                    <label for="mobnoid" v-else
                      >Mobile <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div
                  class="form-group input-group col-md-12"
                  style="border-bottom:1px solid #e5e5e5"
                >
                 
                  <label style="color:#6c757d" class="mr-3"
                    >Gender<span class="required">*</span></label
                  >
                  <label class="web-custradio"
                    >Male
                    <input
                      type="radio"
                      name="radio"
                      v-model="gender"
                      value="male"
                    />
                    <span class="web-checkmark"></span>
                  </label>
                  <label class="web-custradio"
                    >Female
                    <input
                      type="radio"
                      name="radio"
                      v-model="gender"
                      value="female"
                    />
                    <span class="web-checkmark"></span>
                  </label>
                </div>
                <div class="form-group input-group col-md-12">
                  <span class="web-has-float-label">
                    <input
                       name="password"
                      ref="password"
                      type="password"
                      class="form-control form-input"
                      placeholder="Password"
                      v-validate="'required'"
                      v-model="password"
                      autocomplete="off"
                      style="cursor: text;"
                      maxlength="50"
                    />
                    <label
                    v-if="errors.first('parentRegister.password') && showError"
                    class="vi-error"
                    style="top: -1em"
                    >password is required</label
                  >
                    <label for="pwdid" v-else
                      >Password <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-12">
                  <span class="web-has-float-label">
                    <input
                      type="password"
                      name="Confirm Password"
                      placeholder="Password"
                      data-vv-as="password"
                      class="form-control form-input"
                      v-validate="'required|confirmed:password'"
                      v-model="confirmPassword"
                      id="confpwdid"
                      maxlength="50"
                    />

                    <label
                    v-if="errors.first('parentRegister.Confirm Password') && showError"
                    class="vi-error"
                    style="top: -1em"
                    >Confirm Password is required and should match with Password</label
                  >
                    <label for="confpwdid" v-else
                      >Confirm Password <span class="required">*</span></label
                    >
                  </span>
                </div>
               
                <div class="g-recaptcha" id="recaptcha" data-sitekey="6Lcs5UMeAAAAAApF9anTicewdEFZQbZesyw5C0VD" style="width: 100%;"></div>
               
                <v-col cols="12" class="mt-3">
                  <label class="web-custradio">
                    <div slot="label">
                      I accept the
                      <a
                        @click="navTOTermsAndConditions"
                        target="_blank"
                        style="color: #4e4ee2;"
                        >Terms and conditions</a
                      >
                      &
                      <a  style="color: #4e4ee2;"
                        @click="navTOPrivacyPolicy"
                        target="_blank"
                        >Privacy Policy</a
                      >
                    </div>
                    <input
                      value="checked"
                      type="radio"
                      name="terms"
                      v-model="termsAndConditions"
                    />
                    <span class="web-checkmark"></span>
                  </label>
                </v-col>

                <div class="form-group col-md-12 web-btnalign pr-md-3">
                  <button type="submit" @click.prevent="submitForm" class="web-custbutton mt-3 mr-2">
                    Register
                  </button>
                  <input
                    type="button"
                    class="web-cancelbutton mt-3"
                    value="Reset"
                    @click="clearForm"
                  />
                  
                  <!-- <button @click="$router.go(-1)" type="submit" class="web-custbutton mt-3 mr-1">
                     <i class="fa fa-arrow-left"></i> <span>Back</span>
                  </button> -->
                </div>
              </div>
            </form>
            <vi-spinner
              v-if="viLoader"
              text="Loading..."
              textColor="vi-brand-color"
              class="flex-fill h-100 vi-fs12"
              style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
            />
          </div>
           <div class="row" v-else>
               <!-- <div class="col-md-6">
                 <img  src="/img/login_3.33db7843.svg" width="510" alt="Login" class="img-fluid heroimg mt-4">
               </div> -->
                <div class="col-md-12">
           <div class="card web-login-card p-4 mt-5 mb-5 " >
              <div class="forgot-password">
                <div class="dispflex" style="justify-content:start;">
                <div class="cardIcn mr-4" style="padding:0.66rem;width:40px;height:40px;"><i class="fa fa-arrow-left" aria-hidden="true" style="cursor:pointer;font-size:1.2rem" @click="backToRegister"></i></div><h1>Enter 4 digit code sent to your registered email id</h1>
                </div>
                <div class="form-row m-0 mt-3">
                    <div class="form-group input-group mt-3">
                      <span class="web-has-float-label">
                        <input
                          type="number"
                          onKeyPress="if(this.value.length==4) return false;"
                          name="verifyCode"
                           class="form-control web-form-input"
                          id="verifyCodeid"
                          v-model="userVerifyCode"
                          min="4"
                        />
                       
                        <label for="verifyCodeid" 
                          >4-digit code <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <button
                      type="submit"
                      class="web-custbutton mt-3 w-100"
                      @click="verifyCodeForforgotPassword"
                    >
                      Verify
                    </button>
                    <p class="pt-4 w-100" style="text-align:center;">Don't received code? <span style="color:#2954a2;font-weight:bold;cursor:pointer"   @click="resendCode">RESEND CODE</span></p>
                   
                    
                  </div>
              </div>
               <vi-spinner
              v-if="viCodeLoder"
              text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="position: absolute;width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              
            />
          </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";

export default {
  name: "vi-rgister-parent-self",
  components: {
    ViSpinner
  },
  data() {
    return {
      parentRegisterData: {},
      firstName: null,
      lastName: null,
      emailID: null,
      gender: 'male',
      termsAndConditions: '',
      mobileNo: null,
      registering: 'Parent',
      password: null,
      confirmPassword: null,
      serviceError: null,
      viLoader: null,
      viCodeLoder: null,
      showError: true,
      serverSuccess: null,
      isEmailIdAvailable: true,
      termsAndConditionsList: [],
      isPhoneNumberAvailable: true,
      verifyCode : false,
      userVerifyCode: null,
    };
  },

  created() {
    this.getRecentlyUploadedFile();
    this.initReCaptcha();
  },
  methods: {
    backToHome() {
      this.$router.push("/");
    },
    async submitForm() {
         let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("parentRegister").then((result) => {
        isFormValid = result;
      });

      if (isFormValid && this.isEmailIdAvailable && this.isPhoneNumberAvailable) {
         if(this.termsAndConditions){
        const response = window.grecaptcha.getResponse();
        if(response){
        let requestBody = {
          user: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.emailID,
            password: this.confirmPassword,
            phoneNo: Number(this.mobileNo),
            gender: this.gender,
            registeredAs: this.registering,
            userName: this.emailID.toLowerCase()
          },
          type: "parent",
        };
        this.parentRegisterData = requestBody

        this.viLoader = true;
        ViService.viPost("/auth/mailVerification/code/sent", { email: this.emailID })
          .then((res) => {
            this.viLoader = null;
            
            if (res.isSuccess) {
              this.verifyCode = true
              this.$toasted.success(res.message);

            } else {
              this.$toasted.error(res.message);
            }
          })
          .catch((e) => {
            this.viLoader = null;
            let eData = errorLog.apiErrorLog(e);
            this.$toasted.error(eData);
          });

        }else{
          this.$toasted.error('Please Verify Captcha');
       }
        }else{
          this.$toasted.error('Please accept terms and conditions');
       }
      } else {
        this.$toasted.error(message);
      }
    },
    async resendCode() {
      this.viCodeLoder = true;
      ViService.viPost("/auth/resent/code", { email: this.emailID })
          .then((res) => {
            
            if (res.isSuccess) {
              this.viCodeLoder = null;
              this.$toasted.success(res.message);
            } else {
              this.viCodeLoder = null;
              this.$toasted.error(res.message);
            }
          })
          .catch((e) => {
            this.viCodeLoder = null;
            let eData = errorLog.apiErrorLog(e);
            this.$toasted.error(eData);
          });

    },
    async verifyCodeForforgotPassword() {
        if(this.userVerifyCode){
        let requestBody = {
          registerData: this.parentRegisterData,
          code: this.userVerifyCode,
        };
        this.viCodeLoder = true;

        ViService.viPost("/auth/verify/code", requestBody)
          .then((res) => {
            if (res.isSuccess) {
              
              this.$toasted.success("Registration Successful.Please wait....");
              this.showError = false
              setTimeout(() => {
                this.viCodeLoder = null;
                this.$router.push("/login");
              }, 3000);
              this.clearForm();
            }else {
              this.viCodeLoder = null;
             this.$toasted.error(res.message);
            } 
           
          })
          .catch((e) => {
            this.viCodeLoder = null;
            console.log(e);
          });

    }
    else{
      this.$toasted.error('Please enter 4 digit code..');
    }
      
    },
    async checkEmail() {
      this.emailID = this.emailID.toLowerCase();

      const response = await ViService.viGetOnly(
        `/common/checkApiName?cName=User&email=${this.emailID}`
      );
      if (response.isSuccess) {
        this.isEmailIdAvailable = response.isApiNameAvailable;
      } else {
        this.$toasted.error(response.message);
         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
      }
    },
    async navTOTermsAndConditions() {
      window.open("../../../../Terms And Conditions.html");
    },
    async navTOPrivacyPolicy() {
      window.open("../../../../Privacypolicy.html");
    },
    async getRecentlyUploadedFile() {
        const response = await ViService.viGetOnly(
          `/app/termsAndConditions/recent`
        );
        if (response.isSuccess) {
          
          this.termsAndConditionsList = secureUI.secureGet(response.data);
         
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
    },
    async checkMobile() {
        if(this.mobileNo){
         const phoneNo = Number(this.mobileNo)

        const response = await ViService.viGetOnly(`/common/checkApiName?cName=User&phoneNo=${phoneNo}`);
        if (response.isSuccess) {
          this.isPhoneNumberAvailable = response.isApiNameAvailable
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
     }
    },
    async initReCaptcha () {
      setTimeout(() => {
        if (!window.grecaptcha || !window.grecaptcha.render) {
          initReCaptcha();
        } else {
          window.grecaptcha.render("recaptcha", {
            sitekey: '6Lcs5UMeAAAAAApF9anTicewdEFZQbZesyw5C0VD',
            theme: "light"
          });
        }
      }, 500);
    },
    clearForm() {
      this.firstName = null;
      this.lastName = null;
      this.emailID = null;
      this.mobileNo = null;
      this.gender = null;
      this.registering = 'Parent';
      this.password = null;
      this.confirmPassword = null;
      this.serviceError = null;
    },
     backToRegister(){
     this.verifyCode = false
    },
    mxSubmit() {
      console.log('Submit')
    }
  },
};
</script>

<style lang="scss" scoped></style>
